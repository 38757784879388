// Webpixels CSS Utilities
// our utilities are built using the Bootstrap API
// learn more: https://webpixels.io/docs/css

@import "@webpixels/css/utilities";

.h-9 {
  height: 2.25rem !important;
}

.w-9 {
  width: 2.25rem !important;
}
